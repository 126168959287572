import React from 'react';
import Seo from '../../components/Seo';
import MarginContainer from '../../components/MarginContainer';
import PageContentWrapper from '../../components/pageElements/PageContentWrapper';
import Header from './index-header';
import Body from './index-body';
import YAMLData from '../../data/services/services.yaml'

function ProductsPage() {

  return (
    <>
      <Seo title="Ametnes Services" />
      <PageContentWrapper>
        <MarginContainer>
          <Header content={YAMLData}/>
          <Body content={YAMLData}/>
        </MarginContainer>
      </PageContentWrapper>
    </>
  );
}

export default ProductsPage;
